export default {
    // modules
    certificates: 'mdi-certificate',
    companies: 'mdi-office-building',
    contacts: 'mdi-contacts-outline',
    employees: 'mdi-account',
    fields: 'mdi-format-letter-matches',
    files: 'mdi-file-multiple',
    notes: 'mdi-note',
    people: 'mdi-human-male-female',
    projects: 'mdi-account-hard-hat',
    roles: 'mdi-account-group',
    schemes: 'mdi-newspaper',
    schemesessions: 'mdi-newspaper-plus',
    sessions: 'mdi-newspaper-plus',
    settings: 'mdi-cogs',
    tasks: 'mdi-format-list-bulleted',
    tokens: 'mdi-account-key',
    translations: 'mdi-translate',
    trainings: 'mdi-school',
    templates: 'mdi-file-document-edit',
    tags: 'mdi-tag',
    users: 'mdi-account',
    workflows: 'mdi-transit-connection-variant',
    workers: 'mdi-account-box',
    profile: 'mdi-face',
}