<template>
  <v-card class="mb-2" :loading="(loading || save_loading)">
    <v-toolbar dense flat>
      <v-spacer/>
      <v-btn v-if="canDelete" @click="$emit('archive')" text small color="secondary">
        <v-icon color="error">mdi-archive</v-icon>
      </v-btn>
      <v-btn v-if="canEdit" text small @click="save" color="primary">
        <v-icon>mdi-content-save</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider/>
    <v-row>
      <v-col style="margin: 25px" lg="3" sm="12" md="12" class="d-flex justify-center">
        <v-row>
          <v-col>
            <v-row>
              <v-col>
                <base-profile-photo :person="record.person" @refresh="save"/>
              </v-col>
            </v-row>
            <v-row class="mt-5">
              <v-col>
                <slot name="below_photo"/>
              </v-col>
            </v-row>
            <v-row style="margin: 0px" v-if="module_name === 'portal' || module_name === 'profile'">
              <v-col style="padding: 0px; text-align: center">
                <v-btn-toggle light v-model="record.language">
                  <v-btn small value="nl"> NL </v-btn>
                  <v-btn small value="en"> EN </v-btn>
                </v-btn-toggle>
              </v-col>
            </v-row>
            <v-row style="margin: 0px" v-if="module_name === 'portal' || module_name === 'profile'">
              <v-col style="padding: 0px; text-align: center">
                <v-btn class="mt-5" small @click="cleanCache">{{ $store.getters.translate("clean_cache") }} </v-btn><br />
                <small class="mt-2">{{ $store.getters.translate("clean_cache_hint") }}</small>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col style="margin-right: 25px">
        <v-tabs
          v-if="record.person"
          v-model="tab"
          :centered="false"
          :grow="false"
          :vertical="false"
          :right="false"
          :icons-and-text="false">
          <v-tab>{{ $store.getters.translate("general_info") }}</v-tab>
          <v-tab>{{ $store.getters.translate("contact_information") }}</v-tab>
          <v-tab>{{ $store.getters.translate("additional_information") }}</v-tab>
          <v-tab v-if="user_type === 'worker' || (user_type === 'contact' && module_name === 'portal')">{{ $store.getters.translate("financial_information") }}</v-tab>
          <v-tab-item>
            <v-card flat tile>
              <v-card-text>
                <v-row>
                  <v-col>
                    <v-text-field
                        class="ma-0"
                        v-if="record.id && module_name !== 'portal' && module_name !== 'profile'"
                        :label="$store.getters.translate('id')"
                        :value="user_type + ' ID: ' + record.id"
                        disabled/>
                    <v-text-field
                        class="ma-0"
                        :label="$store.getters.translate('first_name')"
                        v-model="record.person.first_name"
                        validate-on-blur>
                      <template #label>{{ $store.getters.translate('first_name') }}<span class="red--text"><strong>*</strong></span></template>
                    </v-text-field>
                    <v-text-field
                        class="ma-0"
                        :label="$store.getters.translate('insertion')"
                        v-model="record.person.insertion"
                        validate-on-blur/>
                    <v-text-field
                        class="ma-0"
                        :label="$store.getters.translate('last_name')"
                        v-model="record.person.last_name"
                        validate-on-blur>
                      <template #label>{{ $store.getters.translate('last_name') }}<span class="red--text"><strong>*</strong></span></template>
                    </v-text-field>
                    <v-text-field
                        class="ma-0"
                        :label="$store.getters.translate('initials')"
                        v-model="record.person.initials"
                        validate-on-blur/>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat tile>
              <v-card-text>
                <v-row>
                  <v-col>
                    <template v-for="field in usual_fields">
                      <template v-if="field.name !== 'name'">
                        <template v-if="field.name === 'jobtitle' || field.name === 'reference'">
                            <base-field
                                v-if="module_name === 'portal' || module_name === 'profile'"
                                :key="field.name"
                                :value="record.person[user_type][field.name]"
                                :field="field"
                                @change="usualFieldDataChanged"/>
                            <base-field
                                v-else
                                :key="field.name"
                                :value="record[field.name]"
                                :field="field"
                                @change="usualFieldDataChanged"/>
                        </template>
                        <template v-else-if="field.name !== 'billingnumber'">
                          <base-field
                              :key="field.name"
                              :value="record.person[field.name]"
                              :field="field"
                              @change="usualFieldDataChanged"/>
                        </template>
                      </template>
                    </template>
                    <v-text-field
                        v-if="record.person.user"
                        :label="$store.getters.translate('user_email')"
                        v-model="record.person.user.email"/>
                    <email-addresses v-model="record.person.emailaddresses" email_type="personal" label="emailaddress" @change="save"/>
                    <base-addresses v-model="record.person.addresses" @change="save" address_type="visiting" label="contact_address" mapID="map1"/>
                    <template v-if="tags_required">
                      <base-tags v-if="module_name === 'portal' || module_name === 'profile'" v-model="record.person[user_type].tags" :module_name="user_type + 's'"/>
                      <base-tags v-else v-model="record.tags" :module_name="user_type + 's'"/>
                    </template>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card flat tile>
              <v-card-text>
                <v-row>
                  <v-col>
                    <template v-for="field in custom_fields">
                      <base-field
                          v-if="module_name === 'portal' || module_name === 'profile'"
                          :key="field.name"
                          :value="record.person[user_type].custom_fields[field.name]"
                          :field="field"
                          @change="customFieldDataChanged"/>
                      <base-field
                          v-else
                          :key="field.name"
                          :value="record.custom_fields[field.name]"
                          :field="field"
                          @change="customFieldDataChanged"/>
                    </template>
                    <base-log-field v-if="module_name !== 'portal' && module_name !== 'profile'" :model_type="user_type" :model_id="record.id"/>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item v-if="user_type === 'worker' || (user_type === 'contact' && module_name === 'portal')">
            <v-card flat tile>
              <v-card-text v-if="user_type === 'worker'">
                <v-row>
                  <v-col>
                    <v-text-field
                        class="ma-0"
                        :label="$store.getters.translate('financial_name')"
                        v-model="record.person.financial_name"
                        validate-on-blur/>
                    <v-text-field
                        class="ma-0"
                        :label="$store.getters.translate('billingnumber')"
                        placeholder="+31612345678"
                        v-model="record.person.billingnumber"
                        validate-on-blur/>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <email-addresses v-model="record.person.emailaddresses" email_type="billing" label="billing_email" @change="save"/>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-switch v-if="user_type === 'worker'" :label="$store.getters.translate('billing_address_the_same')" v-model="hide_billing_address"/>
                  </v-col>
                </v-row>
                <v-row v-if="!hide_billing_address">
                  <v-col>
                    <base-addresses v-model="record.person.addresses" @change="save" address_type="billing" label="billing_address" mapID="map2"/>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-text v-else-if="user_type === 'contact'">
                <template v-for="(company, index) in record.companies">
                  <v-row :key="index">
                    <v-col>
                      <v-text-field
                          class="ma-0"
                          :label="$store.getters.translate('company')"
                          v-model="company.name"
                          validate-on-blur/>
                    </v-col>
                  </v-row>
                  <v-row :key="index">
                    <v-col>
                      <v-text-field
                          class="ma-0"
                          :label="$store.getters.translate('ascription')"
                          v-model="company.ascription"
                          validate-on-blur/>
                    </v-col>
                  </v-row>
                  <v-row :key="index">
                    <v-col>
                      <base-addresses v-model="company.addresses" @change="save" address_type="visiting" label="contact_address" :mapID="'map' + (index * 2)"/>
                    </v-col>
                  </v-row>
                  <v-row :key="index">
                    <v-col>
                      <v-switch class="mt-0" :label="$store.getters.translate('billing_address_the_same')" v-model="hide_company_billing_address[index]" @change="hideCompanyBillingAddress(index)"/>
                    </v-col>
                  </v-row>
                  <v-row v-if="!hide_company_billing_address[index]" :key="index">
                    <v-col>
                      <base-addresses v-model="company.addresses" @change="save" address_type="billing" label="billing_address" :mapID="'map' + ((index * 2) + 1)"/>
                    </v-col>
                  </v-row>
                  <v-divider class="mt-5 mb-10" :key="index"/>
                </template>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
    <slot name="footer"/>
  </v-card>
</template>

<script>
import BaseProfilePhoto from "./BaseProfilePhoto";
import BaseAddresses from "./BaseAddresses";
import BaseLogField from "./BaseLogField";
import helpFunctions from "../../plugins/helpFunctions";
export default {
  name: "BaseProfile",
  props: ["record", "module_name", "user_type", "profile", "save_loading"],
  components: {
    BaseProfilePhoto,
    BaseAddresses,
    BaseLogField,
  },
  data() {
    return {
      tab: 0,
      loading: false,
      usual_fields: [],
      custom_fields: [],
      hide_billing_address: false,
      hide_company_billing_address: [],
      tags_required: false,
    };
  },
  mounted() {
    this.getFields();
  },
  methods: {
    save() {
      let error = false;
      if (this.record.person.first_name === '' || this.record.person.last_name === '') {
        this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate('name'));
        error = true;
      }
      this.usual_fields.forEach((field) => {
        if (field.mandatory && field.field_type !== 'boolean') {
          if(field.name === 'jobtitle' || field.name === 'reference') {
            if(this.module_name === 'portal' || this.module_name === 'profile') {
              if(!this.record.person[this.user_type][field.name] || this.record.person[this.user_type][field.name] === '') {
                this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(field.name));
                error = true;
              }
            }
            else {
              if(!this.record[field.name] || this.record[field.name] === '') {
                this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(field.name));
                error = true;
              }
            }
          }
          else if (field.name !== 'name') {
            if(!this.record.person[field.name] || this.record.person[field.name] === '') {
              this.$toasted.error(this.$store.getters.translate("required_field") + ": " + this.$store.getters.translate(field.name));
              error = true;
            }
          }
        }
      });
      if(!error) {
        if(this.record.person.phonenumber && this.record.person.phonenumber.charAt(0) !== '+') {
          this.$toasted.error(this.$store.getters.translate("international_number_error"));
        }
        else if(this.record.person.mobilenumber && this.record.person.mobilenumber.charAt(0) !== '+') {
          this.$toasted.error(this.$store.getters.translate("international_number_error"));
        }
        else if(this.record.person.billingnumber && this.record.person.billingnumber.charAt(0) !== '+') {
          this.$toasted.error(this.$store.getters.translate("international_number_error"));
        }
        else {
          this.$emit('change');
        }
      }
    },
    usualFieldDataChanged(data) {
      if(data[0] === 'jobtitle' || data[0] === 'reference') {
        if(this.module_name === 'portal' || this.module_name === 'profile') {
          this.record.person[this.user_type][data[0]] = data[1];
        }
        else {
          this.record[data[0]] = data[1];
        }
      }
      else {
        this.record.person[data[0]] = data[1];
      }
    },
    customFieldDataChanged(data) {
      if(this.module_name === 'portal' || this.module_name === 'profile') {
        this.record.person[this.user_type].custom_fields[data[0]] = data[1];
      }
      else {
        this.record.custom_fields[data[0]] = data[1];
      }
    },
    async getFields() {
      this.loading = true;
      let action = 'show_on_update';
      if (this.module_name === "portal") {
        action = 'show_in_portal';
      }
      const response = await helpFunctions.getFields(this.user_type, action);
      this.tags_required = response.tags_required;
      this.usual_fields = response.usual_fields;
      this.custom_fields = response.custom_fields;
      this.refreshAddresses();
      this.loading = false;
    },
    refreshAddresses() {
      if(this.record && this.record.person) {
        if (this.user_type === 'worker') {
          this.record.person.addresses.forEach((address) => {
            if (address.primary) {
              this.hide_billing_address = true;
            }
          });
        }
        else if (this.user_type === 'contact') {
          this.record.companies.forEach((company, index) => {
            this.hide_company_billing_address[index] = false;
            company.addresses.forEach((address) => {
              if (address.primary) {
                this.hide_company_billing_address[index] = true;
              }
            });
          });
        }
      }
    },
    cleanCache() {
      this.$emit('clean_cache');
    },
    canEdit() {
      if (this.profile) {
        return true;
      }
      return this.$can("edit", this.user_type);
    },
    canDelete() {
      if (this.profile) {
        return false;
      }
      return this.$can("delete", this.user_type);
    },
    hideCompanyBillingAddress(index) {
      if(this.user_type === 'contact') {
        if (this.hide_company_billing_address[index]) {
          this.record.companies[index].addresses.forEach((address) => {
            if (address.type === 'billing') {
              this.$http
                  .delete(this.$store.getters.appUrl + "v2/addresses/" + address.id)
                  .then(() => {
                    this.record.companies[index].addresses = this.record.companies[index].addresses.filter((address2) => address2.id !== address.id);
                  })
                  .catch((error) => {
                    this.$toasted.error(error);
                    this.loading = false;
                  });
            } else if (address.type === 'visiting' && !address.primary) {
              address.primary = 1;
              this.$http
                  .patch(this.$store.getters.appUrl + "v2/addresses/" + address.id, address)
                  .then(() => {
                    this.$toasted.success(this.$store.getters.translate("success"));
                  })
                  .catch((error) => {
                    this.$toasted.error(error);
                    this.loading = false;
                  });
            }
          });
        } else {
          this.record.companies[index].addresses.forEach((address) => {
            if (address.type === 'visiting' && address.primary) {
              address.primary = 0;
              this.$http.patch(this.$store.getters.appUrl + "v2/addresses/" + address.id, address);
            }
          });
        }
      }
    }
  },
  watch: {
    record: {
      handler() {
        this.refreshAddresses();
      },
    },
    user_type: {
      handler() {
        this.getFields();
      },
    },
    hide_billing_address: {
      handler() {
        if(this.user_type === 'worker') {
          if (this.hide_billing_address) {
            this.record.person.addresses.forEach((address) => {
              if (address.type === 'billing') {
                this.$http
                    .delete(this.$store.getters.appUrl + "v2/addresses/" + address.id)
                    .then(() => {
                      this.record.person.addresses = this.record.person.addresses.filter((address2) => address2.id !== address.id);
                    })
                    .catch((error) => {
                      this.$toasted.error(error);
                      this.loading = false;
                    });
              } else if (address.type === 'visiting' && !address.primary) {
                address.primary = 1;
                this.$http
                    .patch(this.$store.getters.appUrl + "v2/addresses/" + address.id, address)
                    .then(() => {
                      this.$toasted.success(this.$store.getters.translate("success"));
                    })
                    .catch((error) => {
                      this.$toasted.error(error);
                      this.loading = false;
                    });
              }
            });
          } else {
            this.record.person.addresses.forEach((address) => {
              if (address.type === 'visiting' && address.primary) {
                address.primary = 0;
                this.$http.patch(this.$store.getters.appUrl + "v2/addresses/" + address.id, address);
              }
            });
          }
        }
      },
    },
  },
};
</script>
<style scoped>
.centered-input >>> input {
  text-align: center;
}
.centered-input {
  position: relative;
  top: 7px;
}
.label-custom {
  border: 0px !important;
  font-style: italic;
}
</style>