<template>
  <div>
    <v-list-item v-if="list" @click.stop="load(), $emit('click')">
      <v-item-list-icon>
        <v-icon small class="mr-2">mdi-link</v-icon>
      </v-item-list-icon>
      <v-list-item-title>
        {{ $store.getters.translate(label) }}
      </v-list-item-title>
    </v-list-item>
    <v-btn v-else @click.stop="load(), $emit('click')" block text color="primary" prepend-icon>
      <v-icon class="mr-2">mdi-link</v-icon>
      {{ $store.getters.translate(label) }}
    </v-btn>
    <v-dialog v-model="show" width="70%" :fullscreen="$vuetify.breakpoint.xsOnly">
      <v-card :loading="loading">
        <v-toolbar dark color="primary" dense flat>
          <v-btn icon @click="reset">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $store.getters.translate(module_name) }}</v-toolbar-title>
          <v-spacer/>
          <v-btn text small @click="save">
            <v-icon>mdi-content-save</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider/>
        <v-card-text>
          <v-text-field
            v-model="search"
            v-debounce:250ms.lock="launchSearch"
            @click:clear="clearSearch"
            :label="$store.getters.translate('search')"
            clearable
            class="mb-3 mt-3"
            append-icon="mdi-magnify"/>
          <base-tags v-model="tags" :module_name="module_name"/>
          <v-data-table
            :headers="fields"
            :items="records"
            :loading="loading"
            selectable-key="id"
            class="mt-5"
            show-select
            :single-select="singleSelect"
            v-model="selected"
            :options.sync="options"
            :server-items-length="total_records"
            :items-per-page.sync="options.itemsPerPage"
            :footer-props="footer_props">
            <template v-slot:body="{ items }">
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <td>
                    <v-checkbox v-model="selected" :value="item" style="margin-top: 4px; height: 32px"/>
                  </td>
                  <td v-for="(key, index) in Object.keys(response_fields)" :key="index" :class="[...item[key] == 'deleted' ? ['error--text'] : []]">
                    <!--CHECKBOXES/BOOLEAN VALUES-->
                    <template v-if="response_fields[key] === 'boolean'">
                      <template v-if="item[key] === '1' || item[key] === 1 || item[key] === 'true' || item[key] === true">1</template>
                      <template v-else>0</template>
                    </template>
                    <!--DATES-->
                    <template v-else-if="response_fields[key] === 'date' && item[key]">
                      {{ moment(item[key], "YYYY-MM-DD").format("DD-MM-YYYY") }}
                    </template>
                    <!--DROPDOWNS-->
                    <template v-else-if="(response_fields[key] === 'select_single' || response_fields[key] === 'select_multiple') && item[key] && Array.isArray(item[key])">
                      <v-chip style="height: auto;" class="mr-2" x-small v-for="elem in item[key]" :key="elem">
                        {{ $lodash.truncate(elem, { length: 10 }) }}
                      </v-chip>
                    </template>
                    <!--LINKS-->
                    <template v-else-if="response_fields[key] === 'link'">
                      <a @click="addToSelect(item)">{{ $lodash.truncate(item[key], { length: 30 }) }}</a>
                    </template>
                    <!--ID FIELDS-->
                    <template v-else-if="key === 'id'">
                      {{ item[key] }}
                    </template>
                    <!--TEXT FIELDS-->
                    <template v-else>
                      {{ $lodash.truncate($store.getters.translate(item[key]), { length: 30 }) }}
                    </template>
                  </td>
                </tr>
                <tr v-if="getRecords.length === 0">
                  <td class="text-center grey--text" :colspan="fields.length + 1">
                    <span v-if="!loading">{{ $store.getters.translate("nothing_found") }}</span>
                    <span v-else>{{ $store.getters.translate("loading") }}</span>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import helpFunctions from "../../plugins/helpFunctions.js";

export default {
  props: [
      "module_name",
      "excluded_ids",
      "label",
      "single_select",
      "list"
  ],
  data() {
    return {
      show: false,
      loading: false,
      records: [],
      selected: [],
      all_records: [],
      total_records: 0,
      options: {
        groupBy: [],
        groupDesc: [],
        itemsPerPage: 15,
        multiSort: false,
        mustSort: false,
        page: 1,
        sortBy: [],
        sortDesc: [],
      },
      footer_props: {
        itemsPerPageOptions: [5, 15, 25, 50],
        rowsPerPageText: "Rows per page:",
      },
      search: "",
      original_fields: {},
      response_fields: {},
      fields: [],
      tags: [],
    };
  },
  methods: {
    load() {
      this.show = true;
      this.request();
      this.singleSelect ? (this.selected = null) : (this.selected = []);
    },
    save() {
      this.$emit("change", this.selected);
      this.$emit("refresh");
      this.reset();
    },
    request() {
      if (!this.loading) {
        this.records = [];
        this.loading = true;
        this.$http
          .get(this.$store.getters.appUrl + "v2/" + this.module_name + "?source=site")
          .then((response) => {
            this.all_records = response.data.data;
            this.original_fields = response.data.fields;
            if (this.search) {
              this.response_fields["search_score"] = "text_field";
            }
            Object.entries(this.original_fields).forEach(([key, value]) => {
              this.response_fields[key] = value;
            });
            this.fields = this.composeFields(this.response_fields);
            if (this.excluded_ids) {
              this.all_records = this.all_records.filter((record) => !this.excluded_ids.includes(record.id));
            }
            this.showRecords();
            this.loading = false;
          })
          .catch((error) => {
            if (this.$store.getters.isLoggedIn) {
              this.$toasted.error(error);
            }
            this.loading = false;
          });
      }
    },
    composeFields(fields) {
      let response = [];
      let field_names = Object.keys(fields);
      for (let i = 0; i < field_names.length; i++) {
        response.push({
          text: this.$store.getters.translate(field_names[i]),
          align: "start",
          sortable: true,
          value: field_names[i],
        });
      }
      return response;
    },
    addToSelect(item) {
      if (this.singleSelect) {
        this.selected = item;
      } else {
        this.selected.push(item);
      }
      this.save();
    },
    showRecords() {
      var sorted_array = [];
      if (this.tags.length > 0) {
        sorted_array = helpFunctions.sortByTagsBase(this.all_records, this.tags);
        if (this.search) {
          sorted_array = helpFunctions.searchInSortedArray(sorted_array, this.response_fields, this.search, null);
        }
      } else if (this.search) {
        sorted_array = helpFunctions.searchInSortedArray(this.all_records, this.response_fields, this.search, null);
      } else {
        sorted_array = this.all_records;
      }
      if ((!this.search || this.search.length < 3) && this.options.sortBy[0]) {
        sorted_array = helpFunctions.sortByColumn(sorted_array, this.options.sortBy[0], this.options.sortDesc[0]);
      }
      this.records = [];
      this.total_records = sorted_array.length;
      let start = (this.options.page - 1) * this.options.itemsPerPage;
      var end = this.options.page * this.options.itemsPerPage;
      if (end > sorted_array.length) {
        end = sorted_array.length;
      }
      for (let i = start; i < end; i++) {
        this.records.push(sorted_array[i]);
      }
    },
    clearSearch() {
      this.search = "";
      if (this.all_records && this.all_records.length > 0) {
        delete this.response_fields["search_score"];
        this.fields = this.composeFields(this.response_fields);
        this.showRecords();
      } else {
        this.request();
      }
    },
    launchSearch() {
      if (this.all_records && this.all_records.length > 0) {
        if (this.search) {
          this.response_fields = {};
          this.response_fields["search_score"] = "text_field";
          Object.entries(this.original_fields).forEach(([key, value]) => {
            this.response_fields[key] = value;
          });
        } else {
          delete this.response_fields["search_score"];
        }
        this.fields = this.composeFields(this.response_fields);
        this.showRecords();
      } else {
        this.request();
      }
    },
    reset() {
      this.show = false;
      this.selected = [];
      this.all_records = [];
      this.records = [];
    },
  },
  computed: {
    getRecords() {
      return this.records;
    },
    singleSelect() {
      return !!this.single_select;
    },
  },
  watch: {
    options: {
      handler() {
        if (this.all_records && this.all_records.length > 0) {
          this.showRecords();
        } else {
          this.request();
        }
      },
      deep: true,
    },
    tags: {
      handler() {
        if (this.all_records && this.all_records.length > 0) {
          this.showRecords();
        } else {
          this.request();
        }
      },
    },
  },
};
</script>