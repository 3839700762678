import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFooter } from 'vuetify/lib/components/VFooter';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCombobox,{ref:"menu",attrs:{"label":_vm.$store.getters.translate('tags'),"items":_vm.tags,"outlined":"","chips":"","deletable-chips":"","small-chips":"","multiple":!_vm.single,"item-value":"name.en","item-text":"name.en","return-object":false,"append-icon":"mdi-tag","dense":"","hide-details":"","height":"auto","clearable":""},on:{"click:append":function($event){_vm.getTags();_vm.$refs.menu.blur();_vm.$refs[_vm.modal_name].open()}},model:{value:(_vm.new_value),callback:function ($$v) {_vm.new_value=$$v},expression:"new_value"}}),_c('BaseDraggableModal',{ref:_vm.modal_name,attrs:{"name":_vm.modal_name,"max-height":"80vh","width":"70vw","fullscreen":_vm.$vuetify.breakpoint.xsOnly,"tabindex":"0"},nativeOn:{"blur":function($event){return _vm.$refs[_vm.modal_name].close()}}},[_c(VCard,{attrs:{"loading":_vm.loading}},[_c('BaseModal',[_c(VToolbar,{staticClass:"draggable_selector",attrs:{"slot":"header","dark":"","color":"primary","dense":"","flat":""},slot:"header"},[_c(VToolbarTitle,[_vm._v(_vm._s(_vm.getToolbarTitle))]),_c(VSpacer),_c(VBtn,{attrs:{"text":"","small":""},on:{"click":function($event){$event.stopPropagation();_vm.getTags();_vm.$refs[_vm.modal_name].close()}}},[_c(VIcon,[_vm._v("mdi-content-save")])],1)],1),_c('div',{attrs:{"slot":"content"},slot:"content"},[_c(VDivider),_c(VCardText,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.tags,"loading":_vm.loading,"selectable-key":"id"},scopedSlots:_vm._u([{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm.moment(item.updated_at).format("DD-MM-YYYY HH:mm")))]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{attrs:{"small":""},on:{"click":function($event){return _vm.deleteRecord(item.id)}}},[_vm._v("mdi-delete")])]}}])})],1)],1),_c(VFooter,{attrs:{"slot":"footer"},slot:"footer"},[_c(VTextField,{staticClass:"ml-5",attrs:{"append-outer-icon":"mdi-plus","label":_vm.$store.getters.translate('create'),"single-line":""},on:{"click:append-outer":_vm.save},model:{value:(_vm.record.name),callback:function ($$v) {_vm.$set(_vm.record, "name", $$v)},expression:"record.name"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }