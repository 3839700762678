import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsSlider } from 'vuetify/lib/components/VTabs';
import { VToolbar } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{attrs:{"disabled":_vm.loading,"loading":_vm.loading}},[(_vm.record)?_c(VTabs,{attrs:{"centered":false,"grow":false,"vertical":false,"right":false,"icons-and-text":false},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabsSlider),_c(VTab,[_vm._v(_vm._s(_vm.$store.getters.translate("general_info")))]),(_vm.model === 'company' || _vm.model === 'workflow' || (_vm.record.custom_fields && _vm.record.custom_fields.length > 0))?_c(VTab,[_vm._v(" "+_vm._s(_vm.$store.getters.translate("additional_information"))+" ")]):_vm._e(),_c(VToolbar,{attrs:{"dense":"","flat":""}},[_c(VSpacer),(_vm.canDelete)?_c(VBtn,{attrs:{"text":"","small":"","color":"secondary"},on:{"click":function($event){return _vm.$emit('archive')}}},[_c(VIcon,{attrs:{"color":"error"}},[_vm._v("mdi-archive")])],1):_vm._e(),(_vm.canEdit)?_c(VBtn,{attrs:{"text":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$emit('change')}}},[_c(VIcon,[_vm._v("mdi-content-save")])],1):_vm._e()],1),_c(VTabItem,[_c(VCard,{staticStyle:{"margin":"25px"},attrs:{"flat":"","tile":""}},[_c(VCardText,[_c(VRow,[_c(VCol,[_vm._t("main_fields")],2)],1)],1)],1)],1),_c(VTabItem,[_c(VCard,{staticStyle:{"margin":"25px"},attrs:{"flat":"","tile":""}},[_c(VCardText,[_c(VRow,[_c(VCol,[_vm._t("module_fields")],2)],1)],1)],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }