<template>
  <v-row>
    <v-col>
      <v-card :loading="loading" :disabled="loading">
        <v-toolbar dense flat>
          <!--SEARCH-->
          <v-toolbar-title>
            <v-text-field
              v-model="search"
              v-debounce:230ms.lock="launchSearch"
              @click:clear="clearSearch"
              :label="$store.getters.translate('search')"
              single-line
              hide-details
              clearable
              append-icon="mdi-magnify"/>
          </v-toolbar-title>
          <v-toolbar-title>
            <v-select v-if="page.name !== 'fields'"
              class="ml-10 mt-5"
              v-model="options.search_column"
              :items="search_fields"
              item-value="value"
              item-text="text"
              clearable
              @change="launchSearch"/>
          </v-toolbar-title>
          <v-spacer/>
          <!--EXCEL EXPORT-->
          <download-excel v-if="records.length > 0" class="v-btn v-btn--text theme--light v-size--small" :fields="export_fields" :data="records" :name="page.name + '.xls'">
            {{ $store.getters.translate("export_to_excel") }}
            <v-icon small class="ml-2" color="primary">mdi-file-excel</v-icon>
          </download-excel>
          <v-divider class="mr-2 ml-2" vertical/>
          <!--DELETE-->
          <template v-if="canDelete && selected.length > 0">
            <v-btn color="red" @click="bulkArchive" text small>
              {{ $store.getters.translate("delete") }} {{ selected.length }} {{ $store.getters.translate(page.name) }}
            </v-btn>
          </template>
          <!--IMPORT-->
          <base-import v-if="!base_table_options.no_import" @result="importData" :title="page.name"/>
          <!--CREATE-->
          <template v-if="canCreate && !base_table_options.no_create">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <!--CREATE NEW RECORD-->
                <v-btn
                  v-if="page.name !== 'fields'"
                  @click="$emit('create', true)"
                  v-bind="attrs"
                  v-on="on"
                  text
                  small
                  color="primary">
                  {{ $store.getters.translate("create") }}
                  <v-icon small class="ml-2">mdi-plus</v-icon>
                </v-btn>
                <!--CREATE CUSTOM FIELD ON FIELDS PAGE-->
                <v-btn
                  v-else-if="page.name === 'fields' && selected_model !== 'Role' && selected_model !== 'Translation' &&
                  selected_model !== 'Workflow' && selected_model !== 'Tag' && selected_model !== 'User'"
                  @click="$emit('create', selected_model)"
                  v-bind="attrs"
                  v-on="on"
                  text
                  small
                  color="primary">
                  {{ $store.getters.translate("create") }}
                  <v-icon small class="ml-2">mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>{{ $store.getters.translate("create") }}</span>
            </v-tooltip>
          </template>
        </v-toolbar>
        <v-divider/>
        <v-data-table
          :headers="fields"
          :items="getRecords"
          :loading="loading"
          class="elevation-1"
          item-key="id"
          selectable-key="id"
          :show-select="!base_table_options.no_select"
          v-model="selected"
          :options.sync="options"
          :server-items-length="total_records"
          :disable-pagination="disable_pagination"
          :hide-default-footer="hide_default_footer"
          :footer-props="footer_props"
          :items-per-page.sync="options.itemsPerPage">
          <!--SELECT MODEL OR TAGS FIELD-->
          <template v-if="page.name === 'fields'" v-slot:top>
            <v-card flat>
              <v-card-text>
                <v-select :label="$store.getters.translate('model_type')" v-model="selected_model" :items="$lodash.sortBy(models, 'text')"/>
              </v-card-text>
            </v-card>
          </template>
          <template v-else-if="!base_table_options.no_tags" v-slot:top>
            <v-card flat>
              <v-card-text>
                <base-tags v-model="tags" :module_name="page.name"/>
              </v-card-text>
            </v-card>
          </template>
          <!--CUSTOM DRAGGABLE TABLE FOR FIELDS MODEL ONLY-->
          <template v-if="page.name === 'fields'" v-slot:body="props">
            <draggable :list="props.items" tag="tbody" @end="endDrag(props.items)">
              <tr v-for="(field, index) in props.items" :key="index">
                <td><v-icon small class="page__grab-icon">mdi-arrow-all</v-icon></td>
                <td>{{ field.sort_order }}</td>
                <td>{{ field.id }}</td>
                <td v-if="field.custom_field == 1"><a @click="$emit('edit', field)">{{ $store.getters.translate(field.name) }}</a></td>
                <td v-else>{{ $store.getters.translate(field.name) }}</td>
                <td>{{ $store.getters.translate(field.field_type) }}</td>
                <td><v-checkbox v-model="field.mandatory" :value="field.mandatory" @change="fieldDataChanged(field)"/></td>
                <td><v-checkbox v-model="field.show_in_search" :value="field.show_in_search" @change="fieldDataChanged(field)"/></td>
                <td><v-checkbox v-model="field.show_in_portal" :value="field.show_in_portal" @change="fieldDataChanged(field)"/></td>
                <td><v-checkbox v-model="field.use_in_import" :value="field.use_in_import" @change="fieldDataChanged(field)"/></td>
                <td><v-checkbox v-model="field.show_on_creation" :value="field.show_on_creation" @change="fieldDataChanged(field)"/></td>
                <td><v-checkbox v-model="field.show_on_update" :value="field.show_on_update" @change="fieldDataChanged(field)"/></td>
                <!--ACTION BUTTONS-->
                <td style="text-align: right">
                  <v-icon v-if="canEdit" @click="$emit('edit', field)" small class="mr-2">mdi-pencil</v-icon>
                  <v-icon v-if="field.custom_field == 1" @click="deleteRecord(field.id)" small color="error">mdi-trash-can</v-icon>
                </td>
              </tr>
            </draggable>
          </template>
          <!--USUAL TABLE FOR ALL MODELS-->
          <template v-else v-slot:body="{ items }">
            <tbody>
              <tr v-for="item in items" :key="item.id">
                <td v-if="page.name !== 'fields' && page.name !== 'roles'">
                  <v-checkbox v-model="selected" :value="item" style="margin-top: 4px; height: 32px"/>
                </td>
                <td v-for="(key, index) in Object.keys(response_fields)" :key="index">
                  <!--CHECKBOXES/BOOLEAN VALUES-->
                  <template v-if="response_fields[key] === 'boolean'">
                    <template v-if="item[key] === '1' || item[key] === 1 || item[key] === 'true' || item[key] === true">1</template>
                    <template v-else>0</template>
                  </template>
                  <!--DATES-->
                  <template v-else-if="response_fields[key] === 'date' && item[key] && (key === 'date_of_birth' || key === 'created_at' || key === 'updated_at')">
                    {{ moment(item[key], "YYYY-MM-DD").format("DD-MM-YYYY") }}
                  </template>
                  <!--DROPDOWNS-->
                  <template v-else-if="(response_fields[key] === 'select_single' || response_fields[key] === 'select_multiple') && item[key]">
                    <template v-if="Array.isArray(item[key])">
                      <template v-if="item[key].length > 1">
                        <v-chip style="height: auto;" class="mr-2" x-small v-for="elem in item[key]" :key="elem">
                          {{ elem }}
                        </v-chip>
                      </template>
                      <template v-else>
                        {{ $lodash.truncate($store.getters.translate(item[key][0]), { length: 40 }) }}
                      </template>
                    </template>
                    <template v-else>
                      {{ $lodash.truncate($store.getters.translate(item[key]), { length: 40 }) }}
                    </template>
                  </template>
                  <!--LINKS-->
                  <template v-else-if="response_fields[key] === 'link'">
                    <a @click="$emit('edit', item)">
                      <v-avatar v-if="key === 'name' && base_table_options.avatar" class="mr-1" size="20">
                        <v-img v-if="item.avatar" style="border: 1px" :src="item.avatar"/>
                        <v-img v-else-if="item.person && item.person.avatar" style="border: 1px" :src="item.person.avatar"/>
                        <v-img v-else style="border: 1px" :src="'../../../assets/images/no-user.jpg'"/>
                      </v-avatar>
                      {{ $lodash.truncate(item[key], { length: 50 }) }}
                    </a>
                  </template>
                  <!--ID FIELDS-->
                  <template v-else-if="key === 'id'">
                    <a @click="$emit('edit', item)">{{ item[key] }}</a>
                  </template>
                  <!--TEXT FIELDS-->
                  <template v-else>
                    {{ $lodash.truncate($store.getters.translate(item[key]), { length: 40 }) }}
                  </template>
                </td>
                <!--ACTION BUTTONS-->
                <td style="text-align: right">
                  <v-icon v-if="base_table_options.copy" small class="mr-2" @click="makeCopy(item)">mdi-content-copy</v-icon>
                  <v-icon v-if="canEdit" @click="$emit('edit', item)" small class="mr-2">mdi-pencil</v-icon>
                  <template v-if="!base_table_options.no_select">
                    <v-icon v-if="canDelete" @click="deleteRecord(item.id)" small color="error">mdi-trash-can</v-icon>
                  </template>
                </td>
              </tr>
              <tr v-if="getRecords.length === 0">
                <td class="text-center grey--text" :colspan="fields.length + 1">
                  <span v-if="!loading">{{ $store.getters.translate("nothing_found") }}</span>
                  <span v-else>{{ $store.getters.translate("loading") }}</span>
                </td>
              </tr>
            </tbody>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import BaseImport from "./BaseImport.vue";
import lodash from "lodash";
import Draggable from "vuedraggable";
import helpFunctions from "../../plugins/helpFunctions.js";

export default {
  components: { Draggable, BaseImport },
  props: ["page"],
  data() {
    return {
      loading: false,
      selected: [],
      all_records: [],
      records: [],
      total_records: 0,
      options: {},
      disable_pagination: false,
      hide_default_footer: false,
      footer_props: {
        itemsPerPageOptions: [15, 25, 50, 75, 100, 125, 150, 175, 200],
        rowsPerPageText: "Rows per page:",
      },
      base_table_options: {},
      search: "",
      original_fields: {},
      response_fields: {},
      search_fields: [],
      export_fields: {},
      fields: [],
      tags: [],
      models: [],
      selected_model: "",
    };
  },
  created() {
    if(helpFunctions.getLocalStorageValue(this.page.name + "_options")) {
      this.options = helpFunctions.getLocalStorageValue(this.page.name + "_options");
    }
    if(helpFunctions.getLocalStorageValue(this.page.name + "_search")) {
      this.search = helpFunctions.getLocalStorageValue(this.page.name + "_search");
    }
    if(helpFunctions.getLocalStorageValue(this.page.name + "_tags")) {
      this.tags = helpFunctions.getLocalStorageValue(this.page.name + "_tags");
    }
    this.base_table_options = helpFunctions.base_table_options[this.page.name];
    if (this.page.name === "fields") {
      this.models = helpFunctions.models;
      this.selected_model = "Company";
      this.disable_pagination = true;
      this.hide_default_footer = true;
      this.options.itemsPerPage = 50;
    }
  },
  methods: {
    load: lodash.debounce(function () {
      this.run();
    }, 100),
    run() {
      this.records = [];
      this.all_records = [];
      this.loading = true;
      this.$http
        .get(this.$store.getters.appUrl + "v2/" + this.page.name + "?source=site")
        .then((response) => {
          this.loading = false;
          this.all_records = response.data.data;
          this.original_fields = response.data.fields;
          this.original_fields = helpFunctions.replaceIDwithNameBaseTable(response.data.fields);
          this.search_fields = [];
          this.search_fields.push({
            text: this.$store.getters.translate('all'),
            value: 'all',
          });
          if (this.search && this.search.length > 1) {
            this.response_fields["search_score"] = "text_field";
          }
          Object.entries(this.original_fields).forEach(([key, value]) => {
            this.response_fields[key] = value;
            this.export_fields[this.$store.getters.translate(key)] = key;
            if(key !== '' && key !== 'sort_order' && key !== 'updated_at' && key !== 'created_at' && key !== 'tags') {
              this.search_fields.push({
                text: this.$store.getters.translate(key),
                value: key,
              });
            }
          });
          this.fields = this.composeFields(this.response_fields);
          this.showRecords();
        })
        .catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    composeFields(fields) {
      let response = [];
      let field_names = Object.keys(fields);
      for (let i = 0; i < field_names.length; i++) {
        response.push({
          text: this.$store.getters.translate(field_names[i]),
          align: "start",
          sortable: true,
          value: field_names[i],
        });
      }
      response.push({
        text: this.$store.getters.translate("actions"),
        sortable: false,
        align: "right",
        value: "actions",
      });
      return response;
    },
    showRecords() {
      var sorted_array = [];
      if (this.tags.length > 0) {
        sorted_array = helpFunctions.sortByTagsBase(this.all_records, this.tags);
        if (this.search && this.search.length > 1) {
          sorted_array = helpFunctions.searchInSortedArray(sorted_array, this.response_fields, this.search, this.options.search_column);
        }
      } else if (this.selected_model != "") {
        sorted_array = this.sortByModel();
        if (this.search && this.search.length > 1) {
          sorted_array = helpFunctions.searchInSortedArray(sorted_array, this.response_fields, this.search, this.options.search_column);
        }
      } else if (this.search && this.search.length > 1) {
        sorted_array = helpFunctions.searchInSortedArray(this.all_records, this.response_fields, this.search, this.options.search_column);
      } else {
        sorted_array = this.all_records;
      }
      if (this.options.sortBy[0]) {
        sorted_array = helpFunctions.sortByColumn(sorted_array, this.options.sortBy[0], this.options.sortDesc[0]);
      }
      this.records = [];
      this.total_records = sorted_array.length;
      let start = (this.options.page - 1) * this.options.itemsPerPage;
      var end = this.options.page * this.options.itemsPerPage;
      if (end > sorted_array.length) {
        end = sorted_array.length;
      }
      for (let i = start; i < end; i++) {
        this.records.push(sorted_array[i]);
      }
    },
    sortByModel() {
      var response = [];
      for (let i = 0; i < this.all_records.length; i++) {
        if (this.all_records[i].model_type === this.selected_model) {
          response.push(this.all_records[i]);
        }
      }
      return response;
    },
    clearSearch() {
      this.search = "";
      if (this.all_records && this.all_records.length > 0) {
        delete this.response_fields["search_score"];
        this.fields = this.composeFields(this.response_fields);
        this.showRecords();
      } else {
        this.load();
      }
    },
    launchSearch() {
      if (this.all_records && this.all_records.length > 0) {
        if (this.search && this.search.length > 1) {
          this.response_fields = {};
          this.response_fields["search_score"] = "text_field";
          Object.entries(this.original_fields).forEach(([key, value]) => {
            this.response_fields[key] = value;
          });
        } else {
          delete this.response_fields["search_score"];
        }
        this.fields = this.composeFields(this.response_fields);
        this.showRecords();
      } else {
        this.load();
      }
    },
    endDrag(items) {
      let temp_records = this.records;
      for (let i = 0; i < temp_records.length; i++) {
        for (let z = 0; z < items.length; z++) {
          if (temp_records[i].id === items[z].id && temp_records[i].sort_order !== z + 1) {
            this.records[i].sort_order = z + 1;
            this.fieldDataChanged(this.records[i]);
          }
        }
      }
      this.$toasted.success(this.$store.getters.translate("successfully_saved"));
    },
    fieldDataChanged(field) {
      this.loading = true;
      this.$http
        .patch(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + field.id, field)
        .then(() => {
          this.loading = false;
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    bulkArchive() {
      this.$confirm(this.$store.getters.translate("confirm")).then((res) => {
        if (res) {
          this.loading = true;
          let result = {
            ids: this.$lodash.map(this.selected, "id"),
            model: this.page.model,
          };
          this.$http
            .post(this.$store.getters.appUrl + "v2/bulk/remove", result)
            .then(() => {
              this.loading = false;
              this.$toasted.success(this.$store.getters.translate("success"));
              this.selected = [];
              this.load();
            }).catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toasted.error(error);
              }
              this.loading = false;
            });
        }
      });
    },
    deleteRecord(id) {
      this.$confirm(this.$store.getters.translate("confirm")).then((res) => {
        if (res) {
          this.deleteAction(id);
        }
      });
    },
    async deleteAction(id) {
      this.loading = true;
      return this.$http
        .delete(this.$store.getters.appUrl + "v2/" + this.page.name + "/" + id)
        .then((response) => {
          this.loading = false;
          if (response.data === true) {
            this.load();
          }
          else {
            this.$toasted.error(this.$store.getters.translate("deletion_not_allowed"));
          }
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    makeCopy(item) {
      this.$confirm(this.$store.getters.translate("confirm")).then((res) => {
        if (res) {
          this.loading = true;
          item.model = this.page.model;
          this.$http
            .post(this.$store.getters.appUrl + "v2/bulk/copy", item)
            .then(() => {
              this.loading = false;
              this.$toasted.success(this.$store.getters.translate("success"));
              this.selected = [];
              this.load();
            }).catch((error) => {
              if (this.$store.getters.isLoggedIn) {
                this.$toasted.error(error);
              }
              this.loading = false;
            });
        }
      });
    },
    importData(results) {
      this.$http
        .post(this.$store.getters.appUrl + "v2/bulk/import?model=" + this.page.model, { results: results })
        .then(() => {
          this.loading = false;
          this.$toasted.success(this.$store.getters.translate("success"));
          this.load();
        }).catch((error) => {
          if (this.$store.getters.isLoggedIn) {
            this.$toasted.error(error);
          }
          this.loading = false;
        });
    },
    canCreate() {
      return this.$can("create", this.page.model);
    },
    canEdit() {
      return this.$can("edit", this.page.model);
    },
    canDelete() {
      return this.$can("delete", this.page.model);
    },
  },
  computed: {
    getRecords() {
      return this.records;
    },
  },
  watch: {
    options: {
      handler() {
        helpFunctions.setLocalStorageValue(this.page.name + "_options", this.options);
        if (this.all_records && this.all_records.length > 0) {
          this.showRecords();
        } else {
          this.load();
        }
      },
      deep: true,
    },
    search: {
      handler() {
        helpFunctions.setLocalStorageValue(this.page.name + "_search", this.search);
        this.options.page = 1;
      },
    },
    tags: {
      handler() {
        helpFunctions.setLocalStorageValue(this.page.name + "_tags", this.tags);
        if (this.all_records && this.all_records.length > 0) {
          this.showRecords();
        } else {
          this.load();
        }
      },
    },
    selected_model: {
      handler() {
        this.options.page = 1;
        this.showRecords();
      },
    },
  },
};
</script>